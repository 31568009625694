import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxSplitterModule } from 'igniteui-angular';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { NgxPaginationModule } from 'ngx-pagination';
import { Toast, ToastrModule } from 'ngx-toastr';
import { HttpconfigInterceptor } from './interceptor/httpconfig.interceptor';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { InputTextModule } from 'primeng/inputtext';

import { DropdownModule } from 'primeng/dropdown';

import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';

import { DataShareService } from './services/data-share.service';
import { EventMqttService } from './services/event-mqtt.service';
import { GlobalService } from './services/global.service';
import { PopupService } from './services/popup.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ng-modal-lib';
import { environment } from "src/environments/environment";
import { MyDateAdapter } from './angular-material/MyDateAdapter ';
import { MY_DATE_FORMATS } from './angular-material/my-date-formate';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingEventComponent } from './booking-event/booking-event.component';
import { ChatComponent } from './chat/chat.component';
import { CloseJobComponent } from './close-job/close-job.component';
import { CustomersComponent } from './customers/customers.component';
import { HeaderComponent } from './header/header.component';
import { SearchJobComponent } from './search-job/search-job.component';
import { StickyNoteComponent } from './sticky-note/sticky-note.component';

import { AgendaService, DayService, MonthAgendaService, MonthService, ScheduleModule, TimelineMonthService, TimelineViewsService, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { NumbersOnlyDirective } from './Directive/numbers-only.directive';
import { DriverAlertComponent } from './driver-alert/driver-alert.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { DriverShiftManagementComponent } from './driver-shift-management/driver-shift-management.component';
import { TaskTimelineCalenderComponent } from './task-timeline-calender/task-timeline-calender.component';

import { PaginatorModule } from 'primeng/paginator';
import { FluidHeightDirective } from './Directive/fluid-height.directive';
// import { FabButtonComponent } from './fab-button/fab-button.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ChatBarComponent } from './user-chat/chat-bar/chat-bar.component';
import { ChatLeftSideViewComponent } from './user-chat/chat-bar/chat-left-side-view/chat-left-side-view.component';
import { RecentChatsComponent } from './user-chat/chat-bar/chat-left-side-view/recent-chats/recent-chats.component';
import { UserDetailsComponent } from './user-chat/chat-bar/chat-left-side-view/user-details/user-details.component';
import { ChatBubbleComponent } from './user-chat/chat-bar/chat-middle-view/chat-bubble/chat-bubble.component';
import { ChatMiddleViewComponent } from './user-chat/chat-bar/chat-middle-view/chat-middle-view.component';
import { ChatTopViewComponent } from './user-chat/chat-bar/chat-middle-view/chat-top-view/chat-top-view.component';
import { DaysIndicatorComponent } from './user-chat/chat-bar/chat-middle-view/days-indicator/days-indicator.component';
import { UserChatComponent } from './user-chat/user-chat.component';
import { VisheshChatComponent } from './vishesh-chat/vishesh-chat.component';
import { FabButtonComponent } from './fab-button/fab-button.component';
import { CacheInterceptor } from './interceptor/cache.interceptor';
import { DriverBreakAlertComponent } from './driver-break-alert/driver-break-alert.component';
import { DriverShiftOfflineAlertsComponent } from './driver-shift-offline-alerts/driver-shift-offline-alerts.component';
import { ShiftManagementComponent } from './shift-management/shift-management.component';

import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { ZonequeComponent } from './zoneque/zoneque.component';
import {AccordionModule} from 'primeng/accordion';



const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
	hostname: environment.mqtt.hostName,
	path: environment.mqtt.path,
	protocol: environment.mqtt.protocol === "wss" ? "wss" : "ws",
	clean: environment.mqtt.clean,
	connectTimeout: environment.mqtt.connectTimeout,
	clientId: environment.mqtt.clientId,
	username: environment.mqtt.userName,
	password: environment.mqtt.password,
	reconnectPeriod: environment.mqtt.reconnectPeriod,
	keepalive: environment.mqtt.keepalive
};

const materialModules = [
	MatDatepickerModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatInputModule
];

@NgModule({
	declarations: [
		AppComponent,
		SignInComponent,
		HomeComponent,
		HeaderComponent,
		CustomersComponent,
		BookingDetailsComponent,
		BookingEventComponent,
		CloseJobComponent,
		SearchJobComponent,
		StickyNoteComponent,
		ChatComponent,
		DriverAlertComponent,
		DriverDetailsComponent,
		NumbersOnlyDirective,
		DriverShiftManagementComponent,
		TaskTimelineCalenderComponent,
		UserChatComponent,
		ChatBarComponent,
		ChatLeftSideViewComponent,
		ChatMiddleViewComponent,
		UserDetailsComponent,
		RecentChatsComponent,
		ChatTopViewComponent,
		DaysIndicatorComponent,
		ChatBubbleComponent,
		VisheshChatComponent,
		FluidHeightDirective,
		FabButtonComponent,
		LeaderboardComponent,
		DriverBreakAlertComponent,
		DriverShiftOfflineAlertsComponent,
		ShiftManagementComponent,
  ZonequeComponent,
	],
	imports: [
		AccordionModule,
		ButtonModule,
		ToastModule,
		DropdownModule,
		ScrollPanelModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		MessagesModule,
		ToastModule,
		ConfirmDialogModule,
		InputNumberModule,
		ReactiveFormsModule,
		TooltipModule,
		DialogModule,
		ButtonModule,
		InputTextModule,
		NgxIntlTelInputModule,
		MatTabsModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatSelectModule,
		ModalModule,
		ToastrModule.forRoot(),
		NgxDaterangepickerMd.forRoot(),
		ContextMenuModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		IgxSplitterModule,
		NgxPaginationModule,
		PaginatorModule,
		AgmCoreModule.forRoot({
			apiKey: environment.apiKey,
			libraries: ["places"],
		}),
		BrowserAnimationsModule,
		MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		NgbModule,
		TableModule,

		ScheduleModule,
		ToolbarModule,
		SkeletonModule,
		OverlayPanelModule,
		TabViewModule
	],
	providers: [
		GlobalService,
		PopupService,
		EventMqttService,
		DataShareService,
		{ provide: DateAdapter, useClass: MyDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpconfigInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheInterceptor,
			multi: true
		},
		MessageService,
		DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
