import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-zoneque',
  templateUrl: './zoneque.component.html',
  styleUrls: ['./zoneque.component.scss']
})
export class ZonequeComponent implements OnInit {
  // unsubscribe$!: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    private messageService: MessageService
  ) { }

  dispatcherDetails: any
  zoneViewList: any
  selectedZoneId: any
  zoneQueList: any
  zoneObj: any
  disableBtn: boolean = false
  isLoading: boolean = false;
  isDataMissing: boolean = false;
  warningMessage: string = 'No data found for the selected zone!';
  ngOnInit(): void {
    this.Global.dashboard().then((result: any) => {
      if (result.status) {
        console.log('Entered in ZoneQue component______', this.Global.surgePriceZone)
        this.dispatcherDetails = this.Global.surgePriceZone
        this.zoneViewList = [];
        this.zoneObj = {}

        for (let i = 0; i < this.dispatcherDetails?.length; i++) {
          if (this.dispatcherDetails?.length > 0 && this.dispatcherDetails[i].zoneName.length > 0) {
            console.log('--------------------- this.dispatcherDetails[i].zoneName ---------------------', this.dispatcherDetails[i].zoneName)
            this.zoneViewList.push(this.dispatcherDetails[i].zoneName)
            this.zoneObj[this.dispatcherDetails[i].zoneName] = this.dispatcherDetails[i].zoneId
          }
        }
        console.log('ZoneView List___', this.zoneViewList)
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  activeIndex: number | null = null;
  selectedZone: string = '';
  zoneSelect(zoneName: any): void {
    this.isLoading = true;
    this.selectedZone = zoneName
    let id = this.zoneObj[zoneName]
    console.log('____zone select function', id)
    this.CallMethod.getDriverQueueList({ "zoneId": id }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      console.log('--------------------- result ---------------------', result)
      console.log('--------------------- result.status ---------------------', result.status)
      if (result.status) {
        this.zoneQueList = []
        this.isDataMissing = false
        this.isLoading = false
        this.zoneQueList = result.data;

      }
      else if (result.status === false && result.statusCode == 101) {
        console.log('*********** ZoneId not available. ***************')
        console.warn('ZoneId not available.')
        this.isDataMissing = true
        this.warningMessage = `No data found for ${this.selectedZone} !`;
        this.isLoading = false
        this.zoneQueList = [];
      }

      else {
        console.warn('No carType is available for this zone.')
        this.isDataMissing = true
        this.warningMessage = `No data found for ${this.selectedZone} !`;

      }
    })
  }

  checkDriverObj(driver: any) {
    if (typeof (driver) === 'object' && Object.keys(driver).length > 0) {
      return true
    }
    else return false
  }

  reloadPage() {
    console.log('--------------------- page reloading ---------------------')
    if (this.selectedZone.length > 0) {
      this.isLoading = true; // Show loader
      this.zoneSelect(this.selectedZone);
    }
    else {
      console.log('No Zone selected')
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  }

  // Add an array to track the state of each accordion
  activeIndexes: boolean[] = [];

  // Toggle function to open/close specific accordion items
  toggleAccordion(index: number): void {
    this.activeIndexes[index] = !this.activeIndexes[index];
  }
}
