import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'ng-modal-lib';
import { of, Subject, Subscription } from 'rxjs';
import { CustomersComponent } from '../customers/customers.component';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { EventMqttService } from '../services/event-mqtt.service';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { IMqttMessage } from 'ngx-mqtt';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
	@ViewChild("alertModal") public alertModal!: ModalComponent;
	@ViewChild(CustomersComponent) customerC!: CustomersComponent;
	private subscriptionMqtt: Subscription = new Subscription;

	environment: any = environment;
	moment: any = moment;

	logoutIcon: boolean = false;
	logoutLoader: boolean = true;
	companyColor = '';
	chatBadgeCount: number = 0;
	stickyBadgeCount: number = 0;
	isStickyUpdated: boolean = false;
	isStickyDeleted: boolean = false;
	private subscription: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();
	@ViewChild('settingIconContains') settingIconContains!: ElementRef;

	constructor(
		public Global: GlobalService,
		public CallMethod: CallmethodService,
		public mqtt: EventMqttService,
		public Router: Router,
		public shareData: DataShareService,

	) {
		let companyColor = this.Global.getLocalStorage({ 'key': 'compnayColorsList' });
		companyColor = companyColor.split(",");
		this.companyColor = companyColor[0];

		this.chatBadgeCount = this.Global.getLocalStorage({ 'key': 'chatBadgeCount' });
		this.stickyBadgeCount = this.Global.getLocalStorage({ 'key': 'countStickyAdd' });
		this.isStickyUpdated = this.Global.getLocalStorage({ 'key': 'stickyUpdated' });
		this.isStickyDeleted = this.Global.getLocalStorage({ 'key': 'stickyDeleted' });

		this.subscription = this.shareData.getData().subscribe(data => {
			if (data) {
				if (data.function == 'renderData') {
					if (this.settingIconContains.nativeElement.contains(this.Global.renderData.target)) {
						this.toggleSideBar((this.themeMenu) ? false : true);
					}
					else if (!this.sideBarMenuContains.nativeElement.contains(this.Global.renderData.target)) {
						if (this.themeMenu) {
							this.toggleSideBar(false);
						}
					}
				}
				else if (data.function == 'stickyBadgeCount') {
					this.stickyBadgeCount = this.Global.getLocalStorage({ 'key': 'countStickyAdd' });
					if (this.Global.audioObj.sticky.add) {
						let audio = <HTMLAudioElement>document.getElementById('stickyAudioPlay');
						audio.play();
					}

				}
				else if (data.function == 'stickyUpdated') {
					this.isStickyUpdated = this.Global.getLocalStorage({ 'key': 'stickyUpdated' });
				}
				else if (data.function == 'stickyDeleted') {
					this.isStickyDeleted = this.Global.getLocalStorage({ 'key': 'stickyDeleted' });

				}
				else if (data.function == 'chatBadgeCount') {
					this.chatBadgeCount = this.Global.getLocalStorage({ 'key': 'chatBadgeCount' });
					if (this.Global.audioObj.chat.messageReceive) {
						let audio = <HTMLAudioElement>document.getElementById('chatAudioPlay');
						audio.currentTime = 0;
						audio.play();
					}
				}
				else if (data.function == 'openDriverChat') {
					this.chat();
					this.chatBadgeCount = this.Global.getLocalStorage({ 'key': 'chatBadgeCount' });
				}
				if (data.function == 'driverBreakAlertMqtt' && data.data != '') {
					this.unReadDriverBreakAlert += 1;
					this.Global.setLocalStorage({ 'key': 'driverBreakAlertCount', 'value': this.unReadDriverBreakAlert });
					localStorage.setItem('unReadDriverBreakAlert', JSON.stringify(this.unReadDriverBreakAlert));
					console.log('****** this.unReadDriverBreakAlert ******', this.unReadDriverBreakAlert);
				}
				if (data.function == 'readCounts' && data.data == 'read') {
					this.newRequestDriverShiftCount = 0
				}
			}
		});
	}

	colors: any = [];
	public colorPicker = new Subject<InputEvent>()
	unReadDriverBreakAlert: number = 0;
	ngOnInit(): void {
		this.Global.unReadAlert = (this.Global.getLocalStorage({ 'key': 'unReadAlert' })) ? this.Global.getLocalStorage({ 'key': 'unReadAlert' }) : 0;
		this.Global.unReadDriverAlert = (this.Global.getLocalStorage({ 'key': 'unReadDriverAlert' })) ? this.Global.getLocalStorage({ 'key': 'unReadDriverAlert' }) : 0;
		this.unReadDriverBreakAlert = (this.Global.getLocalStorage({ 'key': 'driverBreakAlertCount' })) ? this.Global.getLocalStorage({ 'key': 'driverBreakAlertCount' }) : 0;
		this.stickyBadgeCount = this.Global.getLocalStorage({ 'key': 'countStickyAdd' });
		this.isStickyUpdated = this.Global.getLocalStorage({ 'key': 'stickyUpdated' });
		this.isStickyDeleted = this.Global.getLocalStorage({ 'key': 'stickyDeleted' });
		this.chatBadgeCount = this.Global.getLocalStorage({ 'key': 'chatBadgeCount' });
		let customColor = this.Global.getLocalStorage({ key: 'customColor' })
		this.newRequestDriverShiftCount = Number(localStorage.getItem('newRequestDriverShiftCount'))
		console.log('****** ion this.newRequestDriverShiftCount ******', this.newRequestDriverShiftCount);
		if (customColor == undefined) {
			customColor = this.Global.first_color
		}

		this.colors.push({
			'data': environment.defaultColor,
			'title': 'Default Colors'
		}, {
			'data': [this.companyColor],
			'title': 'Company Colors'
		}, {
			'data': [customColor],
			'title': 'select Custom Color'
		})

		this.subscription = this.colorPicker.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
			mergeMap((search: any) => of(search).pipe(delay(0))),
		).subscribe((data: any) => {
			this.changeHeaderColor(data)
		});

		const IsSubMqtt = this.Global.getLocalStorage({ key: 'companyId' }) ? this.Global.getLocalStorage({ key: 'companyId' }) : this.Global.companyDetails.companyId
		console.log('***** IsSubMqtt', IsSubMqtt);
		this.subscriptionMqtt = this.mqtt.topic('dispatcher_' + IsSubMqtt).subscribe((data: IMqttMessage) => {
			try {
				console.log("***** dispatcher_' + IsSubMqtt", 'dispatcher' + IsSubMqtt);
				console.log('***** IsSubMqtt mqtt*****', IsSubMqtt);
				let item = JSON.parse(data.payload.toString());
				this.eventHandler(item);
				this.shareData.shareData({ function: 'mqttFromOrderManagement', 'data': item });
			} catch (error) {
				console.log('****** order Management Error ******', error);
			}
		});

	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.subscriptionMqtt.unsubscribe();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	//*************************** side bar */
	menu: boolean = false;
	@ViewChild('sideBarMenuContains') sideBarMenuContains!: ElementRef;
	sideBarMenu() {
		let sideBar = document.getElementById("sideBar")!;
		if (this.menu = !this.menu) {
			this.menu = true;
			sideBar.style.width = '150px';
		}
		else {
			this.menu = false;
			sideBar.style.width = "0px";
		}
	}

	closeSideBar() {
		let sideBar = document.getElementById("sideBar")!;
		sideBar.style.width = "0"
		this.menu = false;
	}

	test() {
		this.Global.setLocalStorage({
			'key': 'colorslist',
			'value': '#fff,#000,#000'
		});

		let colorCode = this.Global.getLocalStorage({ 'key': 'colorslist' });
		colorCode = colorCode.split(",");
		this.Global.first_color = colorCode[0];
		this.Global.second_color = colorCode[1];
		this.Global.third_color = colorCode[2];
	}

	//***************** alert start ******************
	alertPanel: boolean = true;
	alertLoader: boolean = false;
	alertLimit: number = 20;
	alertData: any = [];
	totalAlert: number = 0;
	alertCurrentpage: number = 0;
	showAlertPopup: boolean = false;
	alert(page: any) {
		this.alertCurrentpage = page;
		this.alertPanel = true;
		this.alertLoader = false;
		let alertOffset = (page == 0) ? 0 : ((page * this.alertLimit) - this.alertLimit);

		let passObj = {
			'offset': alertOffset,
			'limit': this.alertLimit
		}

		this.CallMethod.alert(passObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.Global.unReadAlert = 0;
				this.Global.setLocalStorage({ 'key': 'unReadAlert', 'value': this.Global.unReadAlert });
				this.alertData = result.data;
				this.totalAlert = result.totalCount;
			}
			this.alertPanel = false;
			this.alertLoader = true;
		});
	}
	alertModalShow() {
		this.alertModal.modalRoot.nativeElement.style.zIndex = this.alertModal.getMaxModalIndex() + 2;
		if (this.showAlertPopup == false) {
			setTimeout(() => {
				this.alertModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.alert.top;
			}, 0);

			if (this.alertModal.modalBody.nativeElement.style.height.trim() == '') {
				this.alertModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.alert.height;
				let width = parseInt(environment.windowPopupStyle.alert.width.replace('px', ''));
				if (window.innerWidth < parseInt(environment.windowPopupStyle.alert.width.replace('px', ''))) {
					width = window.innerWidth - 2;
				}
				this.alertModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
			}

			this.alertModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
			this.alertModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
			this.alertModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

			this.alertModal.show();
			this.showAlertPopup = true;
		}

		this.alert(0);
	}
	closeAlertModel() {
		this.showAlertPopup = false;
	}
	alertLimitSet(event: any) {
		let value = +event.target.value;
		this.alertLimit = value;
		this.alertData = [];
		this.totalAlert = 0;
		this.alertCurrentpage = 0;
		this.alert(0);
	}
	//***************** alert end ********************

	// ****************** theme settings *****************
	themeMenu: boolean = false;
	toggleSideBar(isopen: any) {
		let sideBar = document.getElementById("themeBar")!;

		if (isopen) {
			this.themeMenu = true;
			sideBar.style.right = '0px';

			let colorCode = this.Global.getLocalStorage({ 'key': 'colorslist' });
			colorCode = colorCode.split(",");

			let obj = <HTMLInputElement>document.getElementById(colorCode[0])
			if (obj != null) {
				let styles = {
					'transform': 'scale(1.05)',
					'border': '2px solid rgba(0,0,0,0.5)',
					'background-color': this.Global.first_color
				}
				Object.assign(obj.style, styles)
			}
		}
		else {
			this.themeMenu = false;
			sideBar.style.right = '-350px';
		}
	}
	dashboardThemeChange(lightThemeButton: any) {
		if (lightThemeButton == true) {
			this.Global.setTheme('lightTheme');
			this.Global.setLocalStorage({
				'key': 'themeType',
				'value': 'lightTheme'
			});
		} else {
			this.Global.setLocalStorage({
				'key': 'themeType',
				'value': 'darkTheme'
			});
			this.Global.setTheme('darkTheme');
		}
	}
	dashboardMapThemeChange(lightThemeButton: any) {
		if (lightThemeButton == true) {
			this.Global.setLocalStorage({
				'key': 'mapThemeType',
				'value': 'lightTheme'
			});
			this.Global.setMapTheme();
		} else {
			this.Global.setLocalStorage({
				'key': 'mapThemeType',
				'value': 'darkTheme'
			});
			this.Global.setMapTheme();
		}
	}
	changeHeaderColor(color: any) {
		let removeStyleBox = <HTMLInputElement>document.getElementById(this.Global.first_color)
		if (removeStyleBox != null) {
			let styleObj = {
				'transform': 'scale(1)',
				'border': '0px solid rgba(0,0,0,0)',
				'background-color': this.Global.first_color
			};
			Object.assign(removeStyleBox.style, styleObj);
		}

		this.Global.first_color = color;

		this.Global.setLocalStorage({
			'key': 'colorslist',
			'value': this.Global.first_color + ',' + this.Global.second_color + ',' + this.Global.third_color
		});

		this.shareData.shareData({ 'function': 'changeThemeColor' });
		this.alertModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
		this.alertModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
		this.alertModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

		let addStyleBox = <HTMLInputElement>document.getElementById(this.Global.first_color);
		if (addStyleBox != null) {

			let styles = {
				'transform': 'scale(1.05)',
				'border': '2px solid rgba(0,0,0,0.5)',
				'background-color': this.Global.first_color
			}

			Object.assign(addStyleBox.style, styles);
		}
	}
	CustomColorPicker(event: any) {
		this.colors[2].data[0] = event.target.value
		this.Global.setLocalStorage({
			'key': 'customColor',
			'value': event.target.value
		});
		this.colorPicker.next(event);
	}

	changeShowAddresstype(type: string) {
		this.Global.isShowAddressType = type;
		this.Global.setLocalStorage({ 'key': 'isShowAddressType', 'value': type });
	}

	changeFindDriverType(type: string) {
		this.Global.findDriverSettings.type = type;
		this.Global.setLocalStorage({ 'key': 'findDriverSettings', 'value': this.Global.findDriverSettings });
		this.shareData.shareData({ "function": "setDriverLimit" });
	}

	changeFindDriverVariant(event: any) {
		this.Global.findDriverSettings.variant = event.target.value;
		this.Global.setLocalStorage({ 'key': 'findDriverSettings', 'value': this.Global.findDriverSettings });
		this.shareData.shareData({ "function": "setDriverLimit" });
	}
	changeFindDriverCount(event: any) {
		this.Global.findDriverSettings.count = event.target.value;
		this.Global.setLocalStorage({ 'key': 'findDriverSettings', 'value': this.Global.findDriverSettings });
		this.shareData.shareData({ "function": "setDriverLimit" });
	}
	manageAlertPopup(event: any, type: string) {
		if (type == 'offlineDriverWarning') {
			this.Global.manageAlertPopup.offlineDriverWarning = event.target.checked;
		}
		else if (type == 'offlineDriver') {
			this.Global.manageAlertPopup.offlineDriver = event.target.checked;
		}

		this.Global.setLocalStorage({ 'key': 'manageAlertPopup', 'value': this.Global.manageAlertPopup });
	}

	logout() {
		this.logoutLoader = false;

		let req = {
			dispatcherSettings: {
				lightThemeButtonChecked: this.Global.lightThemeButtonChecked,
				isMapDark: this.Global.isMapDark,
				isShowAddressType: this.Global.isShowAddressType,
				unassignBookingAlertTime: this.Global.unassignBookingAlertTime,
				unassignBookingAlertOffTime: this.Global.unassignBookingAlertOffTime,
				isSetDriverLimit: this.Global.isSetDriverLimit,
				calculateUnAssignBookingAlertTimeBasedOn: this.Global.calculateUnAssignBookingAlertTimeBasedOn,
				unassignBookingAlertColor: this.Global.unassignBookingAlertColor,
				OnEventShowShimmer: this.Global.OnEventShowShimmer,
				isCopyAccountId: this.Global.isCopyAccountId,
				driverMarkerIcon: this.Global.driverMarkerIcon,
				driverMarkerLabel: this.Global.driverMarkerLabel,
				audioObj: this.Global.audioObj,
				onlineDriverListSortBy: this.Global.onlineDriverListSortBy,
				findDriverSettings: this.Global.findDriverSettings,
				tarrif: this.Global.tarrif,
				minimumAssignMinutes: this.Global.minimumAssignMinutes,
				pickupDelay: this.Global.pickupDelay,
				dropDelay: this.Global.dropDelay,
				paymentDelayTime: this.Global.paymentDelayTime,
				waitTimeColor: this.Global.waitTimeColor,
				waitTimeDelayColor: this.Global.waitTimeDelayColor,
				manageAlertPopup: this.Global.manageAlertPopup,
				createBookingShowsPopup: this.Global.createBookingShowsPopup
			}
		}

		this.CallMethod.logout(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			this.logoutLoader = true;
			if (result.status) {
				this.unSubscribeAllTopics();

				let setLocalStorage = {
					colorCode: this.Global.getLocalStorage({ 'key': 'colorslist' }),
					mapThemeType: this.Global.getLocalStorage({ 'key': 'mapThemeType' }),
					themeType: this.Global.getLocalStorage({ 'key': 'themeType' }),
					customColor: this.Global.getLocalStorage({ 'key': 'customColor' }),
					unassignBookingAlertTime: this.Global.getLocalStorage({ 'key': 'unassignBookingAlertTime' }),
					unassignBookingAlertColor: this.Global.getLocalStorage({ 'key': 'unassignBookingAlertColor' }),
					calculateUnAssignBookingAlertTimeBasedOn: this.Global.getLocalStorage({ 'key': 'calculateUnAssignBookingAlertTimeBasedOn' }),
					OnEventShowShimmer: this.Global.getLocalStorage({ 'key': 'OnEventShowShimmer' }),
					onlineDriverListArraySortBy: this.Global.getLocalStorage({ 'key': 'onlineDriverListArraySortBy' }),
					tarrif: this.Global.getLocalStorage({ 'key': 'tarrif' }),
					loginWithEmailOrPhone: this.Global.getLocalStorage({ 'key': 'loginWithEmailOrPhone' }),
					chatBadgeCount: this.Global.getLocalStorage({ 'key': 'chatBadgeCount' }),
					countStickyAdd: this.Global.getLocalStorage({ 'key': 'countStickyAdd' }),
					stickyDeleted: this.Global.getLocalStorage({ 'key': 'stickyDeleted' }),
					stickyUpdated: this.Global.getLocalStorage({ 'key': 'stickyUpdated' }),
					chatAllDriverList: this.Global.getLocalStorage({ 'key': 'chatAllDriverList' })
				}

				localStorage.clear();
				this.Global.setLocalStorage({
					'key': 'colorslist',
					'value': setLocalStorage.colorCode
				});
				this.Global.setLocalStorage({
					'key': 'mapThemeType',
					'value': setLocalStorage.mapThemeType
				});
				this.Global.setLocalStorage({
					'key': 'themeType',
					'value': setLocalStorage.themeType
				});
				this.Global.setLocalStorage({
					'key': 'customColor',
					'value': setLocalStorage.customColor
				});
				this.Global.setLocalStorage({
					'key': 'unassignBookingAlertTime',
					'value': setLocalStorage.unassignBookingAlertTime
				});
				this.Global.setLocalStorage({
					'key': 'unassignBookingAlertColor',
					'value': setLocalStorage.unassignBookingAlertColor
				});
				this.Global.setLocalStorage({
					'key': 'calculateUnAssignBookingAlertTimeBasedOn',
					'value': setLocalStorage.calculateUnAssignBookingAlertTimeBasedOn
				});
				this.Global.setLocalStorage({
					'key': 'OnEventShowShimmer',
					'value': setLocalStorage.OnEventShowShimmer
				});
				this.Global.setLocalStorage({
					'key': 'onlineDriverListArraySortBy',
					'value': setLocalStorage.onlineDriverListArraySortBy
				});
				this.Global.setLocalStorage({
					'key': 'tarrif',
					'value': setLocalStorage.tarrif
				});
				this.Global.setLocalStorage({
					'key': 'loginWithEmailOrPhone',
					'value': setLocalStorage.loginWithEmailOrPhone
				});
				this.Global.setLocalStorage({
					'key': 'chatBadgeCount',
					'value': setLocalStorage.chatBadgeCount
				});
				this.Global.setLocalStorage({
					'key': 'countStickyAdd',
					'value': setLocalStorage.countStickyAdd
				});
				this.Global.setLocalStorage({
					'key': 'stickyDeleted',
					'value': setLocalStorage.stickyDeleted
				});
				this.Global.setLocalStorage({
					'key': 'stickyUpdated',
					'value': setLocalStorage.stickyUpdated
				});
				this.Global.setLocalStorage({
					'key': 'chatAllDriverList',
					'value': setLocalStorage.chatAllDriverList
				});
				this.Router.navigateByUrl('sign-in');
			}
		});
	}

	loadPage(page: string) {
		// this.Global.setLocalStorage({
		// 	'key': 'openPage',
		// 	'value': page
		// });
		// this.Global.openPage = page;
		this.Router.navigateByUrl(page);
	}

	unSubscribeAllTopics() {


		// if (this.subscribedDriver.length > 0) {
		// 	for (let i = 0; i < this.subscribedDriver.length; i++) {


		// 	}
		// }

		// if (unSubscribe.length > 0) {
		// 	for (let i = 0; i < unSubscribe.length; i++) {
		// 		if (this.subscribedDriverMqtt.get(unSubscribe[i])) {
		// 			let topicUnSub = this.subscribedDriverMqtt.get(unSubscribe[i]);
		// 			topicUnSub.unsubscribe();
		// 		}
		// 		this.subscribedDriverMqtt.delete(unSubscribe[i]);
		// 	}
		// }
	}
	searchCustomer() {
		this.Global.openCustomersPopup = 'header';
		setTimeout(() => {
			this.customerC.customerDetailsModalShow();
		}, 50);
	}

	setUnassignBookingAlertTime(event: any) {
		this.Global.setLocalStorage({ 'key': 'unassignBookingAlertTime', 'value': event.target.value });
		this.Global.unassignBookingAlertTime = event.target.value
		this.shareData.shareData({ "function": "reloadUnassignBookingList" })
	}

	setUnassignBookingAlertOffTime(event: any) {
		this.Global.setLocalStorage({ 'key': 'unassignBookingAlertOffTime', 'value': event.target.value });
		this.Global.unassignBookingAlertOffTime = event.target.value
	}

	unassignbookingAlertTimeColorPicker(event: any) {
		let unassignBookingColorEle = <HTMLInputElement>(
			document.getElementById(this.Global.unassignBookingAlertColor)
		);

		if (unassignBookingColorEle != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(unassignBookingColorEle.style, styles);
		}
		this.Global.setLocalStorage({
			key: "unassignBookingAlertColor",
			value: event.target.value,
		});

		this.Global.unassignBookingAlertColor = event.target.value;
	}

	calculateUnAssignBookingAlertTimeBasedOn(event: any) {
		this.Global.setLocalStorage({ 'key': 'calculateUnAssignBookingAlertTimeBasedOn', 'value': event.target.value });
		this.Global.calculateUnAssignBookingAlertTimeBasedOn = event.target.value;
		this.shareData.shareData({ "function": "reloadUnassignBookingList" });
	}

	showShimmerOnReload(event: any) {
		this.Global.setLocalStorage({ 'key': 'OnEventShowShimmer', 'value': event.target.checked });
		this.Global.OnEventShowShimmer = event.target.checked;
	}
	setDriverLimit(event: any) {
		this.Global.setLocalStorage({ 'key': 'isSetDriverLimit', 'value': event.target.checked });
		this.Global.isSetDriverLimit = event.target.checked;
		this.shareData.shareData({ "function": "setDriverLimit" });
	}

	isCopyAccountId(event: any) {
		this.Global.setLocalStorage({ 'key': 'isCopyAccountId', 'value': event.target.checked });
		this.Global.isCopyAccountId = event.target.checked;
	}

	sortOnlineDriverListArray(event: any, type: String) {
		if (event.target.value != "") {
			if (type == 'freeDriver') {
				this.Global.onlineDriverListSortBy.freeDriverList = event.target.value;
			}
			else if (type == 'acceptedDriver') {
				this.Global.onlineDriverListSortBy.acceptedBookingDriverList = event.target.value;
			}
			else if (type == 'onTripDriver') {
				this.Global.onlineDriverListSortBy.ontripBookingDriverList = event.target.value;
			}
			else if (type == 'completedDriver') {
				this.Global.onlineDriverListSortBy.completedBookingDriverList = event.target.value;
			}
			this.Global.setLocalStorage({ 'key': 'onlineDriverListArraySortBy', 'value': this.Global.onlineDriverListSortBy });
			this.shareData.shareData({ 'function': 'setOnlineDriverList' });
		}
	}

	closedJob() {
		this.shareData.shareData({ 'function': 'closeJob' })
	}

	driverAlert() {
		this.shareData.shareData({ 'function': 'driverAlert' })
	}

	driverBreakAlert() {
		this.shareData.shareData({ 'function': 'driverBreakAlert' })
		localStorage.removeItem('driverBreakAlertCount')
		this.unReadDriverBreakAlert = 0
	}

	searchJob() {
		this.shareData.shareData({ 'function': 'searchJob' })
	}

	stickyNotes() {
		this.shareData.shareData({ 'function': 'stickyNotes' });
		this.stickyBadgeCount = 0;
		this.isStickyUpdated = false;
		this.isStickyDeleted = false;
	}

	chat() {
		this.shareData.shareData({ 'function': 'chat' });
		this.chatBadgeCount = 0;
	}

	userChat() {
		// window.open("chat/","_blank");
		window.open("chat/", "_blank");
	}
	dispatcherLeaderboard() {
		window.open("leaderboard", "_blank");
	}
	zoneQueList() {
		window.open("zone-que", "_blank");
	}
	tarrifSelect(event: any) {
		this.Global.setLocalStorage({ 'key': 'tarrif', 'value': event.target.value });
		this.Global.tarrif = event.target.value;
		this.shareData.shareData({ 'function': 'setTarrifValue' });
	}
	setMinimumAssignMin(event: any) {
		this.Global.setLocalStorage({ 'key': 'minimumAssignMinutes', 'value': event.target.value });
		this.Global.minimumAssignMinutes = event.target.value;
	}


	showMarkerLable(event: any) {
		this.Global.driverMarkerLabel.isShow = event.target.checked;
		this.Global.setLocalStorage({ 'key': 'driverMarkerLabel', 'value': this.Global.driverMarkerLabel });
		this.shareData.shareData({ 'function': 'mapReloadForDriverMarkerLabel' });
	}

	driverMarkerLabelColor(event: any) {
		let driverMarkerColorEle = <HTMLInputElement>(
			document.getElementById(this.Global.driverMarkerLabel.color)
		);

		if (driverMarkerColorEle != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(driverMarkerColorEle.style, styles);
		}
		this.Global.driverMarkerLabel.color = event.target.value;
		this.Global.setLocalStorage({
			key: "driverMarkerLabel",
			value: this.Global.driverMarkerLabel,
		});
		this.shareData.shareData({ function: "mapReloadForDriverMarkerLabel" });
	}

	driverMarkerLabelFontSize(event: any) {
		this.Global.driverMarkerLabel.fontSize = event.target.value;
		this.Global.setLocalStorage({ 'key': 'driverMarkerLabel', 'value': this.Global.driverMarkerLabel });
		this.shareData.shareData({ 'function': 'mapReloadForDriverMarkerLabel' });
	}

	driverMarkerLabelFontWeight(event: any) {
		this.Global.driverMarkerLabel.fontWeight = event.target.value;
		this.Global.setLocalStorage({ 'key': 'driverMarkerLabel', 'value': this.Global.driverMarkerLabel });
		this.shareData.shareData({ 'function': 'mapReloadForDriverMarkerLabel' });
	}

	driverMarkerLabelPosition(event: any, position: string) {
		if (position == 'x') {
			this.Global.driverMarkerIcon.labelOrigin = { x: Number(event.target.value), y: this.Global.driverMarkerIcon.labelOrigin.y }
		} else if (position == 'y') {
			this.Global.driverMarkerIcon.labelOrigin = { y: Number(event.target.value), x: this.Global.driverMarkerIcon.labelOrigin.x }
		}
		this.Global.setLocalStorage({ 'key': 'driverMarkerIcon', 'value': this.Global.driverMarkerIcon });
		this.shareData.shareData({ 'function': 'mapReloadForDriverMarkerLabel' });
	}

	setDriverMarkerIconHeightWidth(event: any, heightOrWidth: string) {
		if (heightOrWidth == "height") {
			this.Global.driverMarkerIcon.height = Number(event.target.value);
		} else if (heightOrWidth == "width") {
			this.Global.driverMarkerIcon.width = Number(event.target.value);
		}
		this.Global.setLocalStorage({
			key: "driverMarkerIcon",
			value: this.Global.driverMarkerIcon,
		});
		this.shareData.shareData({ function: "mapReloadForDriverMarkerLabel" });
	}
	pickupDelayTime(event: any) {
		// this.Global.pickupDelay = Number(event.target.value);
		this.Global.pickupDelay = {
			time: Number(event.target.value),
			color: this.Global.pickupDelay.color,
		};
		this.Global.setLocalStorage({
			key: "pickupDelay",
			value: this.Global.pickupDelay,
		});
		this.shareData.shareData({ function: "setOnlineDriverList" });
	}
	pickupDelayColorPicker(event: any) {
		let pickupDelayColorEle = <HTMLInputElement>(
			document.getElementById("pickupDelayColor")
		);

		if (pickupDelayColorEle != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(pickupDelayColorEle.style, styles);
		}
		this.Global.pickupDelay = {
			time: this.Global.pickupDelay.time,
			color: event.target.value,
		};
		this.Global.setLocalStorage({
			key: "pickupDelay",
			value: this.Global.pickupDelay,
		});
	}

	dropDelayTime(event: any) {
		// this.Global.pickupDelay = Number(event.target.value);
		this.Global.dropDelay = {
			time: Number(event.target.value),
			color: this.Global.dropDelay.color,
		};
		this.Global.setLocalStorage({
			key: "dropDelay",
			value: this.Global.dropDelay,
		});
		this.shareData.shareData({ function: "setOnlineDriverList" });
	}
	dropDelayColorPicker(event: any) {
		let dropDelayColorEle = <HTMLInputElement>(
			document.getElementById("dropDelayColor")
		);

		if (dropDelayColorEle != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(dropDelayColorEle.style, styles);
		}
		this.Global.dropDelay = {
			time: this.Global.dropDelay.time,
			color: event.target.value,
		};
		this.Global.setLocalStorage({
			key: "dropDelay",
			value: this.Global.dropDelay,
		});
	}

	paymentDelayTime(event: any) {
		// this.Global.pickupDelay = Number(event.target.value);
		this.Global.paymentDelayTime = {
			time: Number(event.target.value),
			color: this.Global.paymentDelayTime.color,
		};
		this.Global.setLocalStorage({
			key: "paymentDelayTime",
			value: this.Global.paymentDelayTime,
		});
		this.shareData.shareData({ function: "setOnlineDriverList" });
	}
	paymentDelayTimeColorPicker(event: any) {
		let paymentDelayColorEle = <HTMLInputElement>(
			document.getElementById("paymentDelayTimeColor")
		);

		if (paymentDelayColorEle != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(paymentDelayColorEle.style, styles);
		}
		this.Global.paymentDelayTime = {
			time: this.Global.paymentDelayTime.time,
			color: event.target.value,
		};
		this.Global.setLocalStorage({
			key: "paymentDelayTime",
			value: this.Global.paymentDelayTime,
		});
	}
	waitTimeColorPicker(event: any, isDelayColor: boolean) {
		let ele: any;

		if (isDelayColor) {
			ele = <HTMLInputElement>document.getElementById("delayWaitTimeColor");

			this.Global.waitTimeDelayColor = event.target.value;
			this.Global.setLocalStorage({
				key: "waitTimeDelayColor",
				value: this.Global.waitTimeDelayColor,
			});
		} else {
			ele = <HTMLInputElement>document.getElementById("waitTimeColor");
			this.Global.waitTimeColor = event.target.value;
			this.Global.setLocalStorage({
				key: "waitTimeColor",
				value: this.Global.waitTimeColor,
			});
		}

		if (ele != null) {
			let styles = {
				transform: "scale(1.05)",
				border: "2px solid rgba(0,0,0,0.5)",
				"background-color": event.target.value,
			};

			Object.assign(ele.style, styles);
		}
	}

	BookingAlertAudio(event: any, type: string) {
		if (type == 'sos') {
			this.Global.audioObj.bookingAlert.sos = event.target.checked;
		}
		else if (type == 'rejectBooking') {
			this.Global.audioObj.bookingAlert.rejectBooking = event.target.checked;
		}
		else if (type == 'rejectBookingAutomatic') {
			this.Global.audioObj.bookingAlert.rejectBookingAutomatic = event.target.checked;
		}
		else if (type == 'driverCancelBooking') {
			this.Global.audioObj.bookingAlert.driverCancelBooking = event.target.checked;
		}
		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
	}

	DriverAlertAudio(event: any, type: string) {

		if (type == 'offlineWarning') {
			this.Global.audioObj.driverAlert.offlineWarning = event.target.checked;
		}
		else if (type == 'online') {
			this.Global.audioObj.driverAlert.online = event.target.checked;
		}
		else if (type == 'offline') {
			this.Global.audioObj.driverAlert.offline = event.target.checked;
		}
		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
		// this.shareData.shareData({ 'function': 'mapReloadForDriverMarkerLabel' });
	}

	stickyAudio(event: any, type: string) {
		if (type == 'add') {
			this.Global.audioObj.sticky.add = event.target.checked;
		}
		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
	}
	chatAudio(event: any, type: string) {
		if (type == 'message') {
			this.Global.audioObj.chat.messageReceive = event.target.checked;
		}
		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
	}
	userChatAudio(event: any, type: string) {
		if (type == 'userChatAudio') {
			this.Global.disableUserChatNotification = event.target.checked;
			console.log('***** this.Global.disableUserChatNotification *****', this.Global.disableUserChatNotification);
		}
		this.Global.setLocalStorage({ 'key': 'userChatAudio', 'value': this.Global.disableUserChatNotification });
	}
	unassignTabAudio(event: any, type: string) {
		if (type == 'bookingAssignRemainder') {
			this.Global.audioObj.unassignTab.bookingAssignRemainder = event.target.checked;
		}

		if (event.target.checked == true) {
			this.shareData.shareData({ 'function': 'startUnassignBookingRemainder' });
		}
		else {
			this.shareData.shareData({ 'function': 'stopUnassignBookingRemainder' });
		}

		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
	}

	manageAlertCreatedBookingSound(event: any, type: string) {
		if (type == 'createAcceptBooking') {
			this.Global.audioObj.createBookingNotifyAlert.dispatcherAccept = event.target.checked;
		}
		else if (type == 'createDeclineBooking') {
			this.Global.audioObj.createBookingNotifyAlert.dispatcherDecline = event.target.checked;
		}
		else if (type == 'createBookingNotify') {
			this.Global.audioObj.createBookingPopupAlert.createBookingPopupNotify = event.target.checked;
		}

		this.Global.setLocalStorage({ 'key': 'audioObj', 'value': this.Global.audioObj });
	}

	setCreateBookingPopUp(event: any, type: string) {
		if (type == 'app') {
			this.Global.createBookingShowsPopup.show_popup_user_create_booking = event.target.checked;
		}
		else if (type == 'dispatcher') {
			this.Global.createBookingShowsPopup.show_popup_dispatcher_create_booking = event.target.checked;
		}
		this.Global.setLocalStorage({ 'key': 'createBookingShowsPopup', 'value': this.Global.createBookingShowsPopup });
	}

	openDriverOfflineRequest() {
		console.log('*****>>> openDriverOfflineRequest <<<******');
		this.shareData.shareData({ function: 'openDriverOfflineRequestModel', data: true })
		localStorage.removeItem('newRequestDriverShiftCount');
	}

	newRequestDriverShiftCount: number = 0;
	eventHandler(opts: any) {
		console.log('****** eventHandler opts ******', opts);
		if (opts.statusFlag && opts.isRestaurant == 0) {
			if (opts.statusFlag == 312) {
				console.log('*****>>> Request New Approve <<<******');
				this.newRequestDriverShiftCount = ++this.newRequestDriverShiftCount;
				localStorage.setItem('newRequestDriverShiftCount', this.newRequestDriverShiftCount.toString())
				let newRequestDriverShiftAlert: any = document.querySelector('#offlineDriverRequestReceived');
				newRequestDriverShiftAlert.play();
			}
		}
	}

}
